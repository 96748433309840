/*
 * Payroll
 */

.coyote-payroll {
  &__title {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 100%;
    color: $midnight-blue;
    cursor: default;
    text-align: center;
  }

  &__margin {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__table {
    display: table;
    width: 100%;
    max-width: 100%;
    margin-top: 2rem;
    font-size: 1rem;
    &-half {
      display: inline-table;
      max-width: 48.5%;
      &:nth-child(2n + 1) {
        margin-left: 3%;
      }
    }

    tr {
      @include transition(background-color 120ms ease-in-out);
      &.active {
        background-color: darken($cloud, 3);
      }
      &:hover {
        background-color: darken($cloud, 5);
      }
    }

    tr + tr,
    thead + tbody tr,
    tbody + tfoot tr {
      border-top: 1px solid lighten($gray, 45);
    }
    thead tr {
      background-color: darken($cloud, 5);
      th {
        font-weight: 700;
      }
    }

    tfoot tr {
      background-color: lighten($cloud, 2);
      background-color: darken($cloud, 2);
      th {
        font-weight: 500;
        color: lighten($midnight-blue, 30);
      }
    }

    th,
    td {
      padding: 0.7rem 1.2rem;
      color: $midnight-blue;
      cursor: default;
    }
  }
}

@media (max-width: $media) {
  .coyote-payroll {
    padding: $padding $padding / 2;
    &__table {
      max-width: 100%;
      font-size: 0.875rem;
      &-half {
        max-width: 100%;
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
    }
  }
}

.perceptions-concept-row {
  display: flex;
  align-items: center;
}
.perceptions-amount-row {
  width: 30%;
}
