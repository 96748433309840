/*
 * Attendances
 */

.attendances-week {
  display: block;
  width: 100%;
  padding: $padding / 1.5 $padding / 3;
  background-color: $cloud;
  @include border-box();

  &__title {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 100%;
    color: $midnight-blue;
    cursor: default;
    text-align: center;
  }

  .day {
    display: inline-block;
    margin-top: 1rem;
    width: 20%;
    font-size: 1rem;
    font-weight: 700;
    line-height: 130%;
    text-align: center;
    color: $midnight-blue;

    .dayTitle-short {
      cursor: default;
      display: none;
    }

    .dayTitle-long {
      cursor: default;
    }

    i {
      display: inline-block;
      margin-top: 0.5rem;
      font-size: 1.5rem;
    }

    &.is-future {
      i {
        color: lighten($midnight-blue, 50);
      }
    }
    .is-selected {
      border-radius: 50%;
      box-shadow: 0 0 0 2px $emerald;
    }
  }

  & + .attendances-week {
    margin-top: $padding;
  }
}

@media (max-width: $media) {
  .attendances-week {
    .day {
      .dayTitle-short {
        display: block;
      }
      .dayTitle-long {
        display: none;
      }
    }
    & + .attendances-week {
      margin-top: $padding / 2;
    }
  }
}
