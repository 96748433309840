/*
 * Card
 */

$background: $white;
$color: $midnight-blue;
$title: $white;
$columns: 12;

.coyote-card {
  display: block;
  background-color: $background;
  border: 2px solid $color;
  box-shadow: 0 2px 6px rgba($color, 0.5);

  @include border-radius(2px);
  @include border-box();

  .padding-top {
    padding-top: $padding;
  }
  .padding-bottom {
    padding-bottom: $padding;
  }
  .padding-top-medium {
    padding-top: calc(#{$padding} * 2);
  }

  &__tabs {
    box-shadow: 0 2px 6px rgba($color, 0.5);
    li {
      color: $white;
      display: flex;
      justify-content: center;
      padding: $padding / 3 0;
      text-align: center;
    }
    a {
      align-items: center;
      background: lighten($color, 10);
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 100%;
      &.is-active {
        background: lighten($color, 20);
      }
      &:hover {
        background: lighten($color, 20);
      }
    }
    ul {
      display: flex;
      justify-content: space-between;
    }
  }

  &__menu {
    display: block;
    margin-bottom: $padding;
    &-title {
      background-color: lighten($color, 10);
      color: $title;
      display: block;
      margin-bottom: $padding;
      margin-top: -$padding;
      padding: $padding / 3 0;
      text-align: center;
      @include border-box();
      @include unselectable();
    }

    button {
      cursor: pointer;
      background-color: transparent;
      color: $color;
      line-height: 100%;
      padding: $padding / 2 $padding / 1.7;
      vertical-align: top;
      &:hover {
        color: lighten($color, 20);
      }
      i {
        display: inline-block;
        background-color: transparent;
        text-align: center;
        vertical-align: middle;
        font-size: 1rem;
        line-height: inherit;
      }
      &:disabled {
        color: lighten($color, 60);
        cursor: not-allowed;
      }
      .label {
        display: inline-block;
        font-size: 0.875rem;
        font-weight: 500;
        color: inherit;
        text-transform: uppercase;
        letter-spacing: 1px;
        vertical-align: middle;
        margin: 0;
      }
      .label + i,
      i + .label {
        margin-left: 0.5rem;
      }
    }
    button + button {
      margin-left: $padding;
    }

    button.item-primary {
      background-color: $emerald;
      color: $white;
      &:disabled {
        background-color: lighten($emerald, 30);
      }
      &:hover:enabled {
        background-color: lighten($emerald, 10);
      }
    }

    button.item-danger {
      background-color: $red;
      color: $white;
      &:disabled {
        background-color: lighten($red, 30);
      }
      &:hover:enabled {
        background-color: lighten($red, 10);
      }
    }

    button.item-default,
    a.item-default {
      background-color: $concrete;
      color: $white;
      &:disabled {
        background-color: lighten($concrete, 30);
      }
      &:hover:enabled {
        background-color: lighten($concrete, 10);
      }
    }

    .item-right {
      float: right;
    }
  }

  &__title {
    padding: $padding / 3 $padding;
    background-color: $color;
    border-bottom: 2px solid $color;
    font-size: 1.25rem;
    font-weight: 400;
    color: $title;
    cursor: default;
    @include border-box();
    @include unselectable();
  }

  &__innerBox {
    display: block;
    width: 100%;
    padding: $padding;
    background-color: $cloud;
    @include border-box();
    &-notification {
      text-align: center;
      @include unselectable();
    }
    & + .coyote-card__innerBox {
      margin-top: $padding * 1.5;
    }
  }

  &__menu-buttons {
    padding-left: $padding;
    padding-right: $padding;
  }

  &__title + &__menu {
    margin-top: $padding;
  }

  &__body {
    padding: $padding;
  }

  &__body &__body {
    padding-left: 0;
    padding-right: 0;
  }

  &__body &__body + &__body {
    padding-top: 0;
  }

  &__subtitle {
    display: block;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem !important;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 130%;
    border-bottom: 2px solid lighten($color, 60);
    cursor: default;
    @include border-box();
  }

  .coyote-table + &__subtitle {
    margin-top: $padding;
  }

  /* Columnas custom */
  &__columns {
    display: block;
    width: 100%;
    padding-top: $padding;
    padding-bottom: $padding;
  }
  @for $i from 1 through $columns {
    &__column-#{$i} {
      display: inline-block;
      width: calc((100% / #{$columns} * #{$i}) - #{$padding});
      margin-right: $padding;
      margin-bottom: $padding;
      vertical-align: top;
    }
  }
}

@media (max-width: $media) {
  .coyote-card {
    width: 100%;
    border: none;
    @include border-radius(0);

    .padding-top {
      padding-top: $padding / 2;
    }

    &__title {
      font-size: 1rem;
      font-weight: 500;
    }
    &__body,
    &__subtitle,
    &__menu-buttons {
      padding-top: 0;
      padding-bottom: $padding / 2;
      padding-left: $padding / 2;
      padding-right: $padding / 2;
    }
    &__menu {
      button:not(.show-mobile-label) {
        i {
          margin: 0;
        }
        .label {
          display: none;
          margin: 0;
        }
      }
      button + button {
        margin-left: 0;
      }
    }

    /* Duplicar el ancho de columnas si la pantalla no es tan grande */
    @for $i from 1 through $columns {
      &__column-#{$i} {
        width: calc((100% / #{$columns} * #{$i} * 2) - #{$padding});
      }
    }
  }
}

@media (max-width: $smallMedia) {
  .coyote-card {
    /* Columnas son 100% anchas */
    @for $i from 1 through $columns {
      &__column-#{$i} {
        display: block;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
