.coyote-contact-card {
  padding: 0.7rem;
  background-color: $cloud;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 0.8rem;

  & > a {
    display: inline-flex;
    align-items: center;
    color: $link;
  }

  & > a > i {
    margin-left: 0.3rem;
  }
}
