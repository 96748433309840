#coyote-logo {
  margin: auto;
  padding: 0 0.4rem;
  width: 12rem;
  &.isLogin {
    margin-bottom: 5rem;
    margin-left: auto;
    max-width: 25rem;
    width: initial;
  }
  @media (min-width: $media) {
    margin: 0;
    padding-left: 1.2rem;
  }
}
