/*
 * Job Position History
 */

$wall_width: 1.5rem;

.vertical-wall {
  position: relative;
  left: 200px;
  width: 2px;
  margin-top: 4rem;
  margin-bottom: 10rem;
  @media screen and (max-width: $break-tablet) {
    left: 150px;
  }
  @media screen and (max-width: $break-mobile) {
    left: 10px;
    margin-bottom: 15rem;
  }
  .bullet__container {
    position: relative;
    display: flex;
    border: solid 1px $midnight-blue;
    padding-bottom: 50px;
    &-date {
      position: absolute;
      left: -110px;
      width: 100px;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 2px;
      color: #698bac;
      @media screen and (max-width: $break-mobile) {
        left: 25px;
        top: -3px;
      }
    }
    &-img {
      position: relative;
      top: -7px;
      left: -9px;

      color: $midnight-blue;
    }
    &-info__text {
      min-width: 400px;
      position: relative;
      top: -5px;
      @media screen and (max-width: $break-mobile) {
        width: 290px;
        top: 15px;
        left: 25px;
        line-height: 1.2rem;
        font-size: 0.875rem;
      }
    }
  }

  .bullet__container:last-child {
    border-color: transparent;
  }
}
