$spaceamounts: (5, 10, 15, 20, 25, 30);
$sides: (top, bottom, left, right, x, y, all);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m-#{$space} {
        margin: #{$space}px;
      }

      .p-#{$space} {
        padding: #{$space}px;
      }
    } @else if $side == 'x' {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin: 0 #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding: 0 #{$space}px;
      }
    } @else if $side == 'y' {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin: #{$space}px 0;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding: #{$space}px 0;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}
