.motivometro {
  &__button {
    position: fixed;
    top: 18px;
    left: calc(15rem + 1.6rem);
    z-index: 9999;
    @media (max-width: $media) {
      left: auto;
      right: 1.2rem;
      top: calc(#{$userHeight} + 8px);
    }
    &__thermometer {
      animation: thermometer-thumbnail 6s infinite;
      animation-timing-function: ease-in-out;
    }
    span.icon {
      @media (max-width: $media) {
        margin: 0 !important;
      }
    }
  }
  &__question {
    margin: 1rem 0;
    &__column {
      width: 10%;
      padding: 0.75rem;
      @media (max-width: $media) {
        width: auto;
      }
    }
    &--blocked {
      opacity: 0.4;
      pointer-events: none;
    }
    label {
      @media (max-width: $media) {
        text-align: center;
      }
    }
  }
  &__radio {
    text-align: center;
    font-size: 1rem;
    span i {
      font-size: 1rem;
    }
    .icon {
      cursor: pointer;
    }
    button:focus {
      outline: none;
      box-shadow: none !important;
      border: 0px !important;
    }
  }
  &__box-retro {
    background-color: lighten($gray, 50);
  }
}

@keyframes thermometer-thumbnail {
  0% {
    color: $emerald;
  }
  25% {
    color: $white;
  }
  50% {
    color: $red;
  }
  75% {
    color: $white;
  }
  100% {
    color: $emerald;
  }
}
