/*
 * Menú
 */

$background: $midnight-blue;
$color: $white;
$active: $emerald;

.coyote-menu {
  color: $color;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  height: 0;
  letter-spacing: 1px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: height 0ms 250ms;
  @include unselectable();
  &__status-bar-helper {
    background-color: $midnight-blue;
    height: -constant(safe-area-inset-top);
    height: -env(safe-area-inset-top);
    top: -constant(safe-area-inset-top);
    top: -env(safe-area-inset-top);
    width: 100%;
  }
  &__topbar {
    height: $userHeight;
    height: calc(#{$userHeight} + constant(safe-area-inset-top));
    height: calc(#{$userHeight} + env(safe-area-inset-top));
    background-color: $background;
    border-bottom: 1px solid lighten($background, 10);
    display: flex;
    /* Status bar height on iOS 11.0 */
    padding-top: constant(safe-area-inset-top);
    /* Status bar height on iOS 11+ */
    padding-top: env(safe-area-inset-top);
  }
  &__items {
    background-color: $background;
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$userHeight});
    overflow: auto;
    width: $menuWidth;
    z-index: 5000;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 250ms cubic-bezier(0.77, 0.2, 0.05, 1);
    position: absolute;
    li {
      border-bottom: 1px solid lighten($background, 5);
      &:last-child {
        border-bottom: none;
      }
    }
    &.is-menu-open {
      box-shadow: 2px 0 6px rgba($background, 0.5);
      transform: none;
    }
  }
  &__background {
    background-color: darken($gray, 5);
    height: 100%;
    opacity: 0;
    overflow: hidden;
    width: 100%;
    position: absolute;
    visibility: hidden;
    z-index: 2;
    transition: visibility 0ms 250ms, opacity 250ms ease-in-out;
    &.is-menu-open {
      opacity: 0.5;
      visibility: visible;
      transition: visibility 0ms 0ms, opacity 250ms ease-in-out;
    }
  }
  &__items-wrapper {
    display: flex;
    height: 100%;
  }
  &__item {
    display: flex;
    .mdi {
      margin-right: 1rem;
    }
    .badge {
      align-items: center;
      align-self: center;
      background: red;
      border: 3px solid red;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 0.7rem;
      font-weight: 700;
      height: 1.2rem;
      justify-content: center;
      letter-spacing: normal;
      margin: 0;
      position: absolute;
      right: 1.2rem;
      width: 1.2rem;
    }
  }
  &__menu-icon {
    color: $color;
    background-color: $midnight-blue;
    padding: 1rem;
    &:hover:not(.active) {
      background-color: lighten($background, 5);
    }
    i {
      font-style: normal;
    }
  }
  a {
    color: inherit;
    line-height: 100%;
    padding: 1.2rem;
    @include transition(background-color 70ms ease-in-out);
    @include border-box();
    &.active {
      background-color: lighten($background, 5);
      color: $active;
    }
    &:hover:not(.active) {
      background-color: lighten($background, 5);
    }
    i {
      line-height: 0%;
      vertical-align: middle;
    }
  }
  &__whitespace {
    width: 3.5rem;
  }
  &.is-menu-open {
    height: 100%;
    transition: height 0ms 0ms;
  }
}

@media (min-width: $media) {
  .coyote-menu {
    box-shadow: 2px 0 6px rgba($background, 0.5);
    height: 100%;
    padding-top: 0;
    width: auto;
    &__items {
      position: relative;
      transform: none;
    }
    &__whitespace {
      width: 0;
    }
  }
}
