$background: $white;
$color: $midnight-blue;
$title: $white;

.mdi {
  font-size: large;
  &.badge::after {
    align-items: center;
    background: red;
    border: red solid 8px;
    border-radius: 50%;
    color: #fff;
    content: attr(data-count);
    display: flex;
    font-size: 0.5rem;
    font-weight: 700;
    height: 0.75rem;
    justify-content: center;
    left: 2rem;
    letter-spacing: normal;
    position: absolute;
    top: 1.6rem;
    width: 0.75rem;
  }
}

.button {
  > i {
    margin-right: 0.5rem;
  }
}

.table {
  i {
    height: 1rem;
    vertical-align: middle;
    line-height: 100%;
    margin-right: 0.5rem;
  }
  .button.is-small {
    i {
      height: 0.75rem;
      vertical-align: middle;
      line-height: 100%;
    }
  }
  .button.is-outlined:hover,
  .button.is-outlined:active,
  .button.is-outlined:focus {
    i {
      color: $light;
    }
  }
  .middle-align {
    vertical-align: middle;
  }
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer !important;
}

.upper-case {
  text-transform: uppercase;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.flex-between {
  display: flex !important;
  justify-content: space-between;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
}

.has-info {
  padding-right: 2.25rem !important;
  .info-icon {
    color: $grey;
    position: absolute;
    right: 0;
    top: 0;
    width: 2.25rem;
    &:hover {
      color: darken($grey, 10);
    }
  }
}

.has-text-concret {
  color: $concrete;
}

.is-hidden {
  display: none !important;
}

@media (min-width: $media) {
  .has-info .info-icon {
    margin-left: 0.6rem;
  }
}

form {
  .question-form {
    font-size: 1.25rem;
  }
}

.no-padding-bottom {
  padding-bottom: 0;
}

.is-link.is-transparent {
  background-color: transparent !important;
  &:hover {
    color: darken(#3273dc, 20);
  }
}

.modal {
  z-index: 9999;
}

.is-borderless {
  border: none !important;
}

.list-edit {
  padding: 0.5rem 0.8rem !important;
}

.create-form-group {
  margin-top: 0rem;
  margin-bottom: 1.875rem !important;
}

.settlement-detail {
  background-color: $cloud;
  .deductions-section {
    margin-top: 0.75rem;
  }
}

/*
 * Custom date-picker
 */
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
  }
}
.react-datepicker-popper {
  z-index: 9;
  .coyote-date-time-picker {
    .react-datepicker {
      &__header {
        background-color: darken($midnight-blue, 12);
      }
      &__current-month,
      &__day-name {
        color: lighten($gray, 25);
      }
      &__day {
        &--today {
          color: $black;
          background-color: transparent;
        }
        &--selected {
          color: $white;
          background-color: $emerald;
        }
        &--outside-month {
          color: lighten($black, 85);
          pointer-events: none;
        }
        &--disabled {
          background-color: white;
        }
      }
    }
  }
}

.rotate {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.button-default {
  height: auto;
  background-color: $concrete;
  color: $white;
  padding: 1rem;
  &:hover:enabled {
    background-color: lighten($concrete, 10);
    color: $white;
  }
}

.thin-text {
  font-weight: 400;
}

.no-background {
  background: transparent;
}

.transparent {
  opacity: 0;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.share-event-row {
  width: 100%;
  display: inline-block !important;
}

.is-15percent-bigger {
  transform: scale(1.15);
}

.is-fullwidth-mobile {
  @media screen and (max-width: $break-mobile) {
    width: 100%;
  }
}

.share-task-description {
  line-height: normal;
  margin-top: 0.5rem;
}

.pagination {
  margin-top: 1rem;
}

.top-space {
  margin-top: 1rem;
}

.hide-mobile {
  @media (max-width: $media) {
    display: none;
  }
}

.hide-desktop {
  @media (min-width: $media) {
    display: none !important;
  }
}

.no-label-margin-top {
  margin-top: 2rem;
}

.progress::-webkit-progress-value {
  transition: width 0.5s ease;
}

progress.show-value {
  position: relative;
}

progress.show-value:after {
  content: attr(percentage) '%';
  position: absolute;
  color: $white;
  font-weight: bold;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: calc(1rem / 1.5);
  line-height: 1rem;
}

progress.show-value.is-small:after {
  font-size: calc(0.75rem / 1.5);
  line-height: 0.75rem;
}

progress.show-value.is-medium:after {
  font-size: calc(1.25rem / 1.5);
  line-height: 1.25rem;
}

progress.show-value.is-large:after {
  font-size: calc(1.5rem / 1.5);
  line-height: 1.5rem;
}
