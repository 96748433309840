/*
 * Typography
 */

.typography {
  display: block;
  &__subtitle {
    padding-bottom: 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 130%;
    border-bottom: 2px solid lighten($color, 60);
    cursor: default;
    @include border-box();
    &-center {
      text-align: center;
    }
    &-left {
      text-align: left;
    }
    &-right {
      text-align: right;
    }
  }

  &__standard {
    font-size: 1rem;
    font-weight: 400;
  }
  &__standard-bold {
    font-weight: 600;
  }
  &__standard-light {
    font-weight: 300;
  }
  &__standard-big {
    font-size: 1.125rem;
  }
  &__standard-small {
    font-size: 0.875rem;
  }
}

.semi-bold {
  font-weight: 500;
}

.is-large {
  font-size: 1.125rem;
}

.is-larger {
  font-size: 2rem;
}

.is-bold {
  font-weight: 600;
}

.is-grey {
  color: $grey;
}

.is-italic {
  font-style: italic;
}

.is-striked {
  text-decoration: line-through;
}

.is-small-font {
  font-size: 0.9rem;
}
