$link: #3273dc;

.admin-menu {
  .panel-block {
    color: $link;
    .bullet {
      font-size: 1.25rem;
      padding: 0 0.75rem;
    }
  }
}
