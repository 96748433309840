.notification-container {
  bottom: $padding;
  display: block;
  left: $padding;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 999999;
  @include border-box();

  .notification {
    background-color: $background;
    border-radius: $radius;
    box-shadow: 0 2px 6px rgba(122, 122, 122, 0.3);
    color: $white;
    cursor: default;
    font-size: 0.75rem;
    font-weight: 700;
    letter-spacing: 1px;
    padding: $padding / 2 $padding / 1.5;
    pointer-events: auto;
    position: relative;
    opacity: 0.9;
    width: 430px;

    @include border-box();
    @include transition(opacity 70ms ease-in-out);

    .close {
      position: absolute;
      right: 0.5em;
      top: 0.5em;
    }
    &.is-danger {
      background-color: $danger;
    }
    &.is-info {
      background-color: $info;
    }
    &.is-success {
      background-color: $success;
    }
    &.is-warning {
      background-color: $warning;
    }
    &:hover,
    &:focus {
      opacity: 1;
      cursor: pointer;
    }
  }
}

@media (max-width: $media) {
  .notification-container {
    bottom: 0;
    left: 0;

    .notification {
      width: 100%;
      border-radius: 0;
      margin: 0;
    }
  }
}
