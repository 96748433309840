/*
 * List
 */

.coyote-timetable {
  width: 100%;
  &__day {
    font-size: 1rem;
    margin-top: $padding !important;
    &-checkin,
    &-checkout,
    &-foodtime {
      display: inline-block;
      vertical-align: top;
    }
    &-checkin,
    &-checkout {
      width: 40%;
    }
    &-foodtime {
      width: 20%;
    }
    &-title,
    &-time {
      line-height: 100%;
      white-space: nowrap;
      @include unselectable();
    }
    &-title {
      font-weight: 600;
    }
    &-time {
      display: inline-block;
      margin-top: 0.5rem;
      width: auto;
      &-hour {
      }
      &-half {
        margin-left: 0.25rem;
        font-size: 0.75rem;
      }
      & + & {
        &:before {
          display: inline-block;
          content: '–';
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }
      }
    }
  }
}

@media (max-width: $media) {
  .coyote-timetable {
    &__day {
      &-checkin,
      &-checkout,
      &-foodtime {
        display: block;
        width: 100%;
        & + & {
          margin-top: 1rem;
        }
      }
      &-title {
        font-size: 0.875rem;
      }
      &-foodtime {
        display: none;
      }
    }
  }
}
