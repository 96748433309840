/*
 * Table / List
 */

.coyote-table {
  li {
    display: block;
    padding: $padding / 2;
    border-bottom: 1px solid lighten($concrete, 30);

    @include border-box();

    &:last-child {
      border-bottom: none;
    }
  }
  .margin-icon {
    margin-left: 0.75rem;
    @media (max-width: $media) {
      margin-left: 0;
      margin-right: 0.75rem;
    }
  }
  &__left,
  &__right {
    display: inline-block;
  }

  &__left {
    padding-right: 0.75rem;
    text-align: right;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 220%;
    color: $concrete;
    cursor: default;
    vertical-align: top;
    &.is-emoji {
      font-size: 1.1rem;
    }
  }

  &__faded {
    @include opacity(0.5);
  }
  &__item-main {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 140%;
    cursor: default;
    margin-bottom: 0.25rem;
    @include border-box();
    small {
      display: inline-block;
      margin-left: 1rem;
      font-size: 0.75rem;
      font-weight: 500;
      letter-spacing: 2px;
      margin-top: 0;
      color: lighten($midnight-blue, 30);
    }
  }
  &__item-secondary {
    display: block;
    font-size: 1rem;
    font-weight: 300;
    line-height: 160%;
    cursor: default;
    @include border-box();

    strong {
      font-weight: 500;
      color: inherit;
    }
  }
  &__item-actions {
    margin-top: 0.75rem;
  }
  &__label {
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: $concrete;
    cursor: default;
  }
  &__caption {
    font-weight: 500;
    margin-left: 0.5rem;
  }
  &__flag {
    width: 2rem;
    vertical-align: middle;
    margin-left: 0.5rem;
  }
  &--info {
    color: $concrete;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

@media (max-width: $media) {
  .coyote-table {
    &__is-active {
      background-color: $water;
    }
    &__hide-mobile {
      display: none;
    }
    &__display-block {
      display: block;
    }
  }

  .reduce-padding {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

.grid-table {
  display: grid;
  margin: 1em;

  &--two {
    grid-template-columns: 1fr;
    gap: 1.5rem 1rem;
    @media screen and (min-width: $break-desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.table-container {
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
}
