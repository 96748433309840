#user {
  align-content: center;
  align-items: center;
  background-color: transparent;
  display: flex;
  height: $userHeight;
  justify-content: flex-end;
  margin-bottom: -#{$userHeight};
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  position: fixed;
  right: 9px;
  z-index: 1001;
  @media screen and (max-width: $break-mobile) {
    right: 0px;
  }
  .clickable-area {
    display: flex;
  }
  .name {
    display: none;
    font-weight: 500;
    color: $text;
  }
  .image {
    margin-right: 1.2rem;
    color: $white;
  }
  .logout {
    background-color: lighten($midnight-blue, 3);
    box-shadow: 0 2px 6px rgba($midnight-blue, 0.5);
    cursor: pointer;
    padding: 1.2rem;
    position: absolute;
    right: 0.8rem;
    top: 100%;
    white-space: nowrap;
    width: 8rem;
    &:hover {
      background-color: lighten($midnight-blue, 5);
    }
    a {
      color: $white;
      line-height: 100%;
      font-weight: 500;
      @include transition(background-color 70ms ease-in-out);
      @include border-box();
      i {
        line-height: 0%;
        margin-right: 1rem;
        vertical-align: middle;
      }
    }
  }
  img {
    border-radius: 50%;
    max-height: 30px;
    max-width: 30px;
    vertical-align: bottom;
    &:hover {
      box-shadow: 0 0 0 2px $white;
    }
  }
  .header-pipe-separator {
    padding: 0 1.2rem;
  }
  .motivometro-button {
    background-color: transparent;
    cursor: pointer;
    color: $text;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: underline;
  }
}

@media (min-width: $media) {
  #user {
    background-color: rgba($water, 0.9);
    width: calc(100% - #{$menuWidth});
    z-index: 999;
    .clickable-area {
      cursor: pointer;
      &:hover {
        .name {
          text-decoration: underline;
        }
        img {
          box-shadow: 0 0 0 2px $midnight-blue;
        }
      }
    }
    .name {
      display: block;
    }
    .image {
      color: $midnight-blue;
      margin-left: 0.5rem;
    }
    .logout {
      background-color: $white;
      border: solid $midnight-blue 2px;
      box-shadow: 0 2px 6px rgba($midnight-blue, 0.5);
      right: 1.2rem;
      &:hover {
        background-color: darken($water, 5);
      }
      a {
        color: $midnight-blue;
      }
    }
  }
}
