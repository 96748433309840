// Constants
$TOTAL_LEADERSHIP_LEVELS: 7;

// Colors
$box-border-color: #e0e0e0;
$line-conectors-color: $gray;
$box-background: #f7f9f9;
$focused-background: #e6e6e6;

// Sizes
$text-size: 0.875rem;
$card-width: 380px;
$tabulator-margin-left: 30px;
$empty-card: calc(#{$card-width} - #{$tabulator-margin-left});
$top-boss-card-spacing: 65px;
$width-line-conectors: 2px;

.main-box {
  position: relative;
  width: $card-width;
  padding: 0.5rem;
  border: 1px solid $box-border-color;
  display: block;
  float: left;
  clear: both;
  text-align: center;
  font-size: $text-size;
  background-color: $box-background;
}
.focused-box {
  background-color: $focused-background;
}

.empty-box {
  width: $empty-card;
  position: relative;
  left: $tabulator-margin-left;
  padding: 1rem 0;
  border-left: $width-line-conectors solid $line-conectors-color;
  display: block;
  float: left;
  clear: both;
  text-align: center;
  font-size: $text-size;
  font-weight: 500;
  &:nth-child(3) {
    padding-bottom: 53px;
  }
  &:nth-child(4) {
    padding-bottom: 53px;
  }
}
.aux-box {
  width: $tabulator-margin-left;
  height: 100px;
  position: relative;
  left: $tabulator-margin-left;
  display: block;
  float: left;
  clear: both;
  border-left: $width-line-conectors solid $line-conectors-color;
  border-top: $width-line-conectors solid $line-conectors-color;
  &__inner {
    width: $card-width;
    position: absolute;
    top: calc(-78px / 2);
    left: calc(#{$tabulator-margin-left} - #{$width-line-conectors});
    padding: 0.5rem;
    background-color: $box-background;
    border: 1px solid $box-border-color;
    text-align: center;
    font-size: $text-size;
  }
  &__dot-connector {
    position: absolute;
    right: -5px;
    top: -5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: $gray;
  }
  &:last-child {
    border-left-color: transparent;
  }
}
.thumb-link {
  margin: 10px;
  &:hover {
    color: $white;
  }
}
.main-box p,
.aux-box__inner p {
  margin-top: 5px;
}
.main-box span.icon,
.aux-box__inner span.icon {
  background-color: $midnight-blue;
  border-radius: 50%;
}

@mixin leadershipShadow($opacityLevel) {
  box-shadow: 3px 3px 5px 0px
    rgba(44, 62, 80, 1 / $TOTAL_LEADERSHIP_LEVELS * $opacityLevel);
}

.shadow-0 {
  @include leadershipShadow(0);
}
.shadow-1 {
  @include leadershipShadow(1);
}
.shadow-2 {
  @include leadershipShadow(2);
}
.shadow-3 {
  @include leadershipShadow(3);
}
.shadow-4 {
  @include leadershipShadow(4);
}
.shadow-5 {
  @include leadershipShadow(5);
}
.shadow-6 {
  @include leadershipShadow(6);
}
.shadow-7 {
  @include leadershipShadow(7);
}

.top-boss-card {
  position: relative;
  clear: none;
  margin-left: $top-boss-card-spacing;
}

.top-bosses-label-wrapper {
  position: absolute;
  width: $top-boss-card-spacing;
  left: -$top-boss-card-spacing;
  top: 50%;
  border-top: 2px solid $line-conectors-color;
}

.join-team-form {
  position: relative;
  align-items: center;
  width: 555px;
  height: 100%;
  padding-top: 15px;
  left: -19px;
  text-align: left;
  &__colaborator-button {
    margin-right: 13px;
    border-radius: 50%;
    &:disabled {
      background-color: #97ebde !important;
      opacity: 1;
    }
  }
  &__fields {
    width: 100%;
    padding-left: 47px;
  }
}

.leadership__employeeId {
  margin-right: 8px;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  color: #95a5a6;
}

.leadership__textbox {
  line-height: normal;
  &-link-name {
    text-decoration: underline;
  }
}

.leadership__no-team {
  top: -38px;
}

.leadership__add-colaborator-text {
  padding-top: 7px;
  position: relative;
}

.leadership__icons-row {
  margin-top: 10px !important;
  margin-bottom: 5px;
  & .icon {
    width: 1.5rem;
    height: 1.5rem;
    line-height: normal;
    color: $white;
    & i {
      font-size: $text-size;
    }
  }
  .extension-thumb {
    background-color: transparent !important;
    color: $midnight-blue;
    width: auto;
    margin-left: 8px;
  }
}

.resuelve-product__logo {
  position: absolute;
  left: calc(100% - 22px);
  top: 5px;
  width: 18px;
}

.fx-fade {
  animation-name: fx-fade;
  animation-duration: 0.8s;
}

@keyframes fx-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
