.side-form {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    animation: side-form-opacity-in 0.5s;
  }
  &__content {
    position: absolute;
    width: 50%;
    max-width: 768px;
    height: 100%;
    right: 0;
    top: 0;
    background-color: $white;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    animation-timing-function: ease-out;
    animation: side-form 0.5s;
    &__form {
      overflow-y: scroll;
      height: calc(100% - 65px);
      padding: 1.6rem;
    }
    @media (max-width: $media) {
      width: 100%;
    }
  }
}

@keyframes side-form {
  from {
    transform: translate(90%);
  }
  to {
    transform: translate(0);
  }
}

@keyframes side-form-opacity-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
